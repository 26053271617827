/* Modal container */
.helpModal {
    text-align: center;
    line-height: 1.8; /* Increase line height for better readability */
}

/* Title styling */
.modalTitle {
    font-size: 1.8rem; /* Larger and bold title */
    font-weight: bold;
    margin-bottom: 15px;
    color: var(--black);
    border-bottom: 1px solid var(--gray);
}

/* Description styling */
.modalDescription {
    font-size: 1rem; /* Standard readable size */
    margin-bottom: 20px; /* Padding below the description */
    white-space: pre-line; /* Ensure line breaks are respected */
}

/* Actions container */
.modalActions {
    display: flex;
    justify-content: center;
    gap: 15px; /* Space between buttons */
    margin-top: 25px; /* More spacing above the actions */
}

/* Action button styling */
.actionButton {
    color: var(--white);
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

