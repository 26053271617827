/* Banner */
.banner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

/* Left Content */
.bannerContent {
    flex: 1;
}

.leagueTitle {
    font-size: 24px;
    margin-bottom: 5px;
}

.leagueCode {
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.leagueCode span {
    color: var(--red);
    font-weight: bold;
}

.copyButton{
    border-radius: 5px;
}

.copyButton:hover {
    background-color: var(--gray)
}

/* League Metadata */
.leagueMeta {
    margin-top: 10px;
    font-size: 14px;
    opacity: 0.8;
}

/* Right Side Actions */
.bannerActions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}

/* Buttons */
.joinButton, .leaveButton, .deleteButton {
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
}

.joinButton {
    background: var(--red);
    color: white;
}

.joinButton:hover {
    background: var(--gray);
}

.leaveButton {
    background: var(--silver);
    color: var(--black);
}

.leaveButton:hover {
    background: var(--gray);
}

.deleteButton {
    background: var(--red);
    color: white;
}

.deleteButton:hover {
    background: var(--red);
}

/* Leaderboard */
.leaderboardContainer {
        background-color: var(--white);
        border-radius: 12px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        padding: 20px;
}

.tableTitle {
    margin-bottom: 10px;
}

/* Table */
.leaderboardContainer {
    background-color: var(--white);
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    overflow: hidden; /* Ensures the table keeps the rounded corners */
}

/* Table */
.leaderboardTable {
    width: 100%;
    border-collapse: separate; /* Needed for rounded corners */
    border-spacing: 0; /* Prevents gaps */
    background-color: var(--white); /* Default background */
    border-radius: 12px; /* Rounded corners */
    overflow: hidden; /* Ensures corners stay rounded */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1)
}

/* Table Header */
.leaderboardTable thead {
    background-color: var(--red);
    color: var(--white);
}

.leaderboardTable th {
    padding: 12px;
    text-align: left;
}

/* Apply rounded corners to the first and last header cells */
.leaderboardTable th:first-child {
    border-top-left-radius: 12px;
}

.leaderboardTable th:last-child {
    border-top-right-radius: 12px;
}

/* Leaderboard Rows */
.leaderboardTable tbody tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Subtle row dividers */
}

.leaderboardTable tbody tr:hover{
    background-color: var(--gray) !important;
    cursor: pointer;
    scale: 1.01;
}

.leaderboardTable tbody td {
    padding: 10px;
}

/* Top 3 positions */
.position1 td.rank {
    color: var(--gold);
    font-weight: bold;
}

.position2 td.rank {
    color: var(--silver);
}

.position3 td.rank {
    color: var(--bronze);
}

/* Alternating row colors */
.leaderboardTable tbody tr:nth-child(odd) {
    background-color: #f8f8f8;
}

.leaderboardTable tbody tr:nth-child(even) {
    background-color: var(--white);
}

/* Apply rounded corners to the last row */
.leaderboardTable tbody tr:last-child td:first-child {
    border-bottom-left-radius: 12px;
}

.leaderboardTable tbody tr:last-child td:last-child {
    border-bottom-right-radius: 12px;
}


/* Highlighting top 3 */
.position1 {
    background: gold;
    font-weight: bold;
}

.position2 {
    background: silver;
}

.position3 {
    background: #cd7f32;
}
