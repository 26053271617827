/* Full-screen overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Translucent dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

/* Modal container */
.container {
    position: relative;
    width: 90%;
    max-width: 800px;
    background-color: var(--white);
    padding:  2rem;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    z-index: 101;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers content horizontally */
    justify-content: center; /* Centers content vertically */
    text-align: center; /* Centers text */
}

/* Close button */
.closeButton {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: var(--gray);
    cursor: pointer;
    transition: color 0.3s ease;
}

.closeButton:hover {
    color: var(--red); /* Highlight close button on hover */
    background-color: inherit !important;
}
