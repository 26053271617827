/* Main header styling */
.eventsHeader {
    font-size: 2rem;
    font-weight: bold;
    color: var(--red);
    text-align: center;
    margin-bottom: 20px;
}

/* Container for event cards */
.eventsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

/* Event card styling */
.eventCard {
    background-color: var(--white);
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-left: 4px solid var(--red);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Header for event title and status */
.eventHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Event title */
.eventTitle {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--black);
}

/* Status indicator */
.eventStatus {
    font-size: 0.9rem;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    color: var(--white);
    text-transform: uppercase;
}

/* Status active/inactive colors */
.statusActive {
    background-color: var(--red);
}
.statusInactive {
    background-color: var(--gray);
    color: var(--black);
}

/* Description styling */
.eventDescription {
    font-size: 1rem;
    color: var(--black);
    line-height: 1.5;
}

/* Footer styling for points and time */
.eventFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    color: var(--black);
}

/* Points styling */
.eventPoints {
    font-weight: bold;
    color: var(--gold);
}

/* Time styling */
.eventTime {
    font-style: italic;
    color: #999;
}

/* Podium layout for 1st, 2nd, and 3rd */
/* Podium container with vertical alignment */
/* Podium container with vertical alignment */
.podiumContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: clamp(0px, 2vw, 50px);
    margin-top: 50px;
}

/* General podium styling */
.podiumCard {
    width: 120px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--gray);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

/* Specific podium heights */
.firstPlace {
    height: 110px; /* Tallest for 1st place */
}

.firstPlaceRank{
    background-color: var(--gold);
}

.secondPlace {
    height: 90px; /* Medium height for 2nd place */
}
.secondPlaceRank{
    background-color: var(--silver);
}

.thirdPlace {
    height: 75px; /* Shortest for 3rd place */
}

.thirdPlaceRank{
    background-color: var(--bronze);
}

/* Podium rank label */
.podiumRank {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--white);
    padding: 5px 10px;
    border-radius: 5px;
    position: absolute;
    top: 10px; /* Positioned near the top of the podium */
}

/* Points styling */
.podiumPoints {
    font-size: 1rem;
    font-weight: bold;
    color: var(--black);
    margin-bottom: 10px;
    position: absolute;
    bottom: 5px;
    text-align: center;
    width: 100%;
}

/* Names positioned above the podium */
.podiumNames {
    position: absolute;
    bottom: calc(100% + 5px); /* Position above the podium block */
    left: 50%;
    min-width: 95%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column-reverse; /* Stack names from the bottom upwards */
    align-items: center;
}

/* Individual name styling */
.podiumName {
    font-size: 0.8rem;
    font-weight: bold;
    color: var(--black);
    background-color: var(--white);
    padding: 5px 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
}

/* Overall section styling */
.suggestionsSection {
    text-align: center; /* Center-align the content */
    max-width: 600px; /* Limit the width */
    margin: 100px auto; /* Center the section on the page */
}

/* Header styling */
.suggestionsHeader {
    font-size: 2rem; /* Large font for the header */
    color: var(--red); /* Theme color for emphasis */
    margin-bottom: 10px; /* Spacing below the header */
}

/* Description text styling */
.suggestionsDescription {
    font-size: 1rem; /* Standard readable font size */
    color: var(--black); /* Neutral color for the text */
    margin-bottom: 20px; /* Space between the text and the button */
    line-height: 1.5; /* Improve readability */
}

/* Button styled as a link */

