/* Container */
.container {
    max-width: 500px;
    margin: 40px auto;
    padding: 20px;
    background: var(--white);
    border-radius: 10px;
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
}

/* Title */
.title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: var(--black);
    margin-bottom: 20px;
}

/* Error message */
.error {
    color: var(--red);
    text-align: center;
    font-weight: bold;
}

/* Form */
.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Labels */
.label {
    font-weight: bold;
    color: var(--black);
    display: flex;
    flex-direction: column;
    gap: 5px;
}

/* Inputs */
.input, .textarea {
    padding: 10px;
    border: 1px solid var(--gray);
    border-radius: 5px;
    font-size: 1rem;
}

/* Textarea */
.textarea {
    resize: vertical;
    min-height: 80px;
}

/* Checkbox */
.checkbox {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: bold;
    color: var(--black);
}

/* Submit Button */
.button {
    background-color: var(--red);
    color: var(--white);
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;
    transition: 0.2s;
    border: none;
}

.button:hover {
    background-color: darkred;
}

.button:disabled {
    background-color: var(--gray);
    cursor: not-allowed;
}
