.cardList {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.title {
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.sectionTitle {
    margin-top: 20px;
    font-size: 1.4rem;
    font-weight: bold;
    color: #333;
    border-bottom: 2px solid #ccc;
    padding-bottom: 5px;
}

.nextEvent {
    text-align: center;
    padding: 15px;
    background-color: #ffebcc;
    border-radius: 10px;
    margin-bottom: 20px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #b36b00;
    border: 1px solid #b36b00;
}

.countdown {
    font-size: 1.4rem;
    color: #d9534f;
    font-weight: bold;
}

.cardItem {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    margin: 10px 0;
    transition: all 0.2s ease-in-out;
}

.cardItem:hover {
    background-color: #f9f9f9;
    cursor: pointer;
}

.expiredCard {
    background-color: #e0e0e0;
    border: 1px solid #aaa;
    pointer-events: none;
}

.expiredText {
    color: red;
    font-weight: bold;
}
