/* 🎮 Overall Container */
.container {
    max-width: 800px;
    margin: 20px auto 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media screen and (max-width: 965px){
    .container{
        margin-top: 60px;
    }
}

/* 🏆 Page Header */
.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.title {
    font-size: 2rem;
    font-weight: bold;
    color: var(--black);
    margin-bottom: 10px;
}
.userLeague{
    position: absolute;
    top: 10px;
    right: 10px;
}

/* 🔍 Search Bar */
.searchContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.searchButton {
    padding: 10px 15px;
}

.actions{
    display: flex;
    justify-content: space-between;
}


.createButton {
    background: var(--gray);
    color: var(--black);
    padding: 10px 15px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.2s;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.createButton:hover{
    background-color: var(--white);
}


/* 🏅 League List */
.leagueList {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* 🛡️ League Card */
.leagueCard {
    background: var(--white);
    padding: 15px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s;
}

.leagueCard:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.leagueDetails {
    display: flex;
    flex-direction: column;
}

.leagueName {
    font-size: 1.3rem;
    font-weight: bold;
    color: var(--black);
}

.leagueMeta {
    font-size: 0.9rem;
    color: var(--gray);
}
