.cabinet {
    padding: 20px;
    background-color: #f7f7f7;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.trophyShelf {
    margin-bottom: 30px;
    padding: 15px 0;
    border-bottom: 2px solid #ddd;
}

.shelfTitle {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.shelfWrapper {
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    padding: 10px 0;
}

.trophySlider {
    display: flex;
    gap: 15px;
    transition: transform 0.3s ease-in-out;
}

.trophyCard {
    max-width: 200px;
    background-color: #fcfcfc;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    flex-shrink: 0; /* Ensures trophies stay horizontal */
}

.trophyCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.trophyName {
    font-size: 1.2rem;
    font-weight: bold;
    color: #444;
    margin-bottom: 8px;
    white-space: wrap;
}

.trophyDetails {
    font-size: 0.9rem;
    color: #666;
    line-height: 1.5;
}

.noTrophies {
    text-align: center;
    color: #999;
    font-style: italic;
    flex-shrink: 0; /* Prevent resizing */
    margin: auto;
}

.empty {
    text-align: center;
    font-size: 1.2rem;
    color: #777;
    padding: 20px;
    margin-top: 20px;
}

/* Navigation buttons */
.navButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
}

.navButton:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.navButton.left {
    left: 10px;
}

.navButton.right {
    right: 10px;
}
