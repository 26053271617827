.countdownContainer {
    background: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    margin: 20px 10px;
}

.countdown {
    font-size: 1rem;
    font-weight: bold;
    color: #d32f2f;
}
