/* Container for the bingo card and header */
.cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: clamp(2px, 2vw, 30px); /* Dynamic padding for the card */
    background-color: var(--white);
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin: clamp(10px, 5vw, 20px) auto; /* Dynamic margin */
    width: 95%;
    max-width: 700px;
}

/* Header section */
.header {
    margin-top: 5px;
    text-align: center;
}

.header h2 {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--red);
    margin: 0;
}

.header p {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--black);
    margin: 5px 0 0;
}

/* Bingo card grid */
.bingoCard {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 columns for a bingo card */
    gap: clamp(3px, 2vw, 10px); /* Dynamic gap between cells */
    width: 100%;
    margin: 0 auto;
}

/* Bingo cell */
.cell {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    background-color: #f7f7f7;
    color: var(--black);
    font-size: clamp(0.8rem, 2vw, 1.1rem); /* Dynamically adjusts font size for titles */
    font-weight: bold;
    position: relative;
    aspect-ratio: 1 / 1; /* Ensures square aspect ratio */
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
    padding: clamp(5px, 2vw, 10px); /* Dynamic padding for the cell */
    word-wrap: break-word; /* Ensures long titles wrap */
    text-align: center;
}

.cell:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

/* Claimed cell styling */
.status-true {
    border: 2px solid var(--red);
    background: linear-gradient(145deg, rgba(255, 24, 1, 0.1), rgba(255, 24, 1, 0.2));
}

.boost-2 {
    background-color: var(--gold);
    animation: pulse 1.5s infinite;
    color: var(--white)
}

.boost-3 {
    background-color: #ffd700;
    animation: pulse 1.5s infinite;
}

/* Expanded Cell Contain
er */
.expandedCellContainer {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto;
}

/* Title Section */
.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd; /* Separator for structure */
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.title {
    font-size: 1.5rem;
    font-weight: bold;
}


/* Description Section */
.description {
    font-size: 1rem;
    color: #333333;
    line-height: 1.5;
    white-space: pre-wrap;
    margin: 20px;
}

/* Status Section */
.status {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 10px;
    border-top: 1px solid #ddd;
    gap: 10px;
    width: 100%;
}

.statusLabel {
    font-size: 0.9rem;
    font-weight: bold;
    padding: 6px 12px;
    border-radius: 5px;
    text-transform: uppercase;
}

.claimed {
    background-color: var(--red);
    color: #ffffff;
}

.locked {
    background-color: #cccccc;
    color: #333333;
}

.boost {
    font-size: 1rem;
    padding: 8px;
    border-radius: 5px;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.silver {
    background-color: #ffe6a1;
    color: #333333;
}

.gold {
    background-color: #ffd700;
    color: #333333;
}

/* Pulse Animation */
@keyframes pulse {
    0% {
        box-shadow: 0 0 4px rgba(255, 214, 120, 0.5);
    }
    50% {
        box-shadow: 0 0 10px rgba(255, 214, 120, 0.8);
    }
    100% {
        box-shadow: 0 0 4px rgba(255, 214, 120, 0.5);
    }
}

/* Responsive design */
@media (max-width: 768px) {
    .bingoCard {
        grid-template-columns: repeat(5, 1fr);
        gap: clamp(5px, 1vw, 8px); /* Smaller gaps on small screens */
    }

    .cell {
        font-size: clamp(0.55rem, 2vw, 2rem); /* Adjust font size for smaller screens */
        padding: clamp(1px, 1vw, 8px); /* Smaller padding on small screens */
    }

    .expandedCellContainer {
        width: 100%; /* Expand full width on smaller screens */
        max-width: none;
    }
}
