/* Main container styling */
.container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    text-align: center;
}

/* Header styling */
.header {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: var(--red);
}


.exampleList {
    margin: 15px 0;
    padding-left: 20px;
    list-style: disc;
}

.exampleList li {
    margin-bottom: 10px;
}

.exampleList em {
    font-style: italic;
    color: var(--gold);
}

.exampleList strong {
    font-weight: bold;
}


/* Form styling */
.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

/* Label styling */
.label {
    font-size: 1rem;
    font-weight: bold;
    color: var(--black);
    text-align: left;
    margin-bottom: 5px;
}

/* Input and textarea styling */
.input,
.textarea {
    background-color: var(--gray);
}


/* Action buttons container */
.actions {
    text-align: center;
}

/* Success message styling */
.successMessage {
    font-size: 1rem;
    color: green;
    margin-top: 15px;
}
