/* Dropdown option styling */
.countryOption {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Flag styling */
.flag {
    width: 24px;
    height: 16px;
    object-fit: cover;
    border-radius: 3px;
}

/* Override react-select styles */
.select {
    width: 100%;
    font-size: 1rem;
}
